<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="10" lg="8" xs="12" sm="12">
        <p class="title">Laboratory Test Results</p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col md="10" lg="8" xs="12" sm="12">
        <div
          v-infinite-scroll="loadMore"
          infinite-scroll-disabled="busy"
          :infinite-scroll-distance="pagination.size"
        >
          <v-container fluid style="padding: 0px">
            <v-row v-for="test in tests" :key="test.id">
              <v-col md="12" lg="12" xs="12" sm="12">
                <v-card outlined style="padding: 0px" class="mx-auto">
                  <v-card-title style="padding: 0px">
                    <template>
                      <v-expansion-panels v-model="panel" multiple>
                        <v-expansion-panel
                          dark
                          :style="{
                            backgroundColor: expandableHeaderBackground,
                          }"
                          style="
                            padding: 0px;
                            border-bottom-left-radius: 0px;
                            border-bottom-right-radius: 0px;
                            text-align: center;
                            position: relative;
                          "
                          class="test--item"
                        >
                          <v-expansion-panel-header dark v-slot="{ open }">
                            <v-container fluid style="padding: 0px">
                              <v-row dense>
                                <v-col
                                  cols="12"
                                  md="6"
                                  lg="6"
                                  xs="12"
                                  sm="12"
                                  class="text--white subtitle-1"
                                  style="text-align: left"
                                  >Order Id : {{ test.orderId }}</v-col
                                >
                                <v-col
                                  cols="12"
                                  class="text--white subtitle-1"
                                  md="6"
                                  lg="6"
                                  xs="12"
                                  sm="12"
                                  >{{
                                    test.orderDate
                                      | moment("Do MMMM  YYYY, h:mm a")
                                  }}</v-col
                                >
                              </v-row>
                            </v-container>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content style="font-size: 14px">
                            <v-row dense>
                              <v-col
                                cols="12"
                                md="6"
                                lg="6"
                                xs="12"
                                sm="12"
                                class="text--white body-2"
                                style="text-align: left"
                                >Patient : {{ test.customer.name }}</v-col
                              >
                              <v-col
                                cols="12"
                                md="6"
                                lg="6"
                                xs="12"
                                sm="12"
                                class="text--white body-2"
                                style="text-align: left"
                                >Referred by : {{ test.doctor }}</v-col
                              >
                            </v-row>
                            <v-row dense>
                              <v-col
                                v-if="test.customer.telephone !== null"
                                cols="12"
                                md="6"
                                lg="6"
                                xs="12"
                                sm="12"
                                class="text--white body-2"
                                style="text-align: left"
                                >Contact : {{ test.customer.telephone }}</v-col
                              >
                              <v-col
                                cols="12"
                                md="6"
                                lg="6"
                                xs="12"
                                sm="12"
                                class="text--white body-2"
                                style="text-align: left"
                                >Sex : {{ test.customer.sex }}</v-col
                              >
                            </v-row>
                            <v-row dense>
                              <v-col
                                cols="12"
                                md="6"
                                lg="6"
                                xs="12"
                                sm="12"
                                class="text--white body-2"
                                style="text-align: left"
                              >
                                Age : {{ test.customer.age }}</v-col
                              >
                              <v-col
                                cols="12"
                                md="6"
                                lg="6"
                                xs="12"
                                sm="12"
                                class="text--white body-2"
                                style="text-align: left"
                                >Address : {{ test.customer.address }}</v-col
                              >
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </template>
                  </v-card-title>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list dense>
                        <v-subheader class="title">Tests</v-subheader>
                        <v-list-item-group
                          color="primary"
                          v-for="(item, index) in test.orderLists"
                          :key="item.id"
                        >
                          <v-list-item @click="">
                            <v-row style="align-items: center;padding-bottom: 10px;">
                              <v-col
                                cols="2"
                                sm="2"
                                md="1"
                                lg="1"
                               
                              >
                                <!-- <v-list-item-title style="height: 5px;line-height: 40px">
                                                                    {{index+1}}
                                                                </v-list-item-title> -->

                                <p>{{ index + 1 }}</p>
                              </v-col>
                              <v-col
                                cols="10"
                                sm="10"
                                md="8"
                                lg="8"
                                
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    style="font-size: 15px"
                                    v-text="item.testName"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-col>

                              <v-col
                                cols="12"
                                sm="12"
                                md="3"
                                lg="3"
                              
                               
                              >
                                
                                  <template
                                    v-if="item.status === REPORT_AVAILABLE"
                                  >
                                    <v-btn
                                      @click="downloadReport(item, test)"
                                      rounded
                                   
                                      color="success"
                                      dark
                                    >
                                      <v-icon>mdi-download</v-icon>
                                      Download</v-btn
                                    >
                                  </template>
                                  <template v-else>
                                    <v-chip
                                      style="margin-top: 0px"
                                      text-color="black"
                                    >
                                      <v-avatar left>
                                        <v-icon>mdi-alert-circle</v-icon>
                                      </v-avatar>
                                      Not available
                                    </v-chip>
                                  </template>
                            
                              </v-col>
                            </v-row>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
var fileDownload = require("js-file-download");
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: process.env.VUE_APP_APPTITLE,
    // all titles will be injected into this template
    titleTemplate: "%s | Laboratory Test Results",
  },

  data() {
    return {
      pagination: { page: 1, size: 5, total: 0 },
      tests: [],
      panel: [0],
    };
  },
  created() {
    console.log("------get test", this.dynamic);
    this.getTests();
  },
  computed: {
    ...mapState(["static", "dynamic"]),
  },
  methods: {
    downloadReport(item, test) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.dynamic.token,
        },
        responseType: "blob",
      };
      this.$Progress.start();
      this.$http
        .get(this.HOST + item.url, config)
        .then((response) => {
          this.$Progress.finish();
          fileDownload(
            response.data,
            test.customer.name + "-" + item.testName + ".pdf"
          );
          link.click();
        })
        .catch((e) => {
          this.$Progress.fail();
        });
    },
    getTests() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.dynamic.token,
        },
      };
      this.$Progress.start();
      this.$http
        .get(this.HOST + "/web/order", config)
        .then((response) => {
          this.tests = response.data.content;
          this.$Progress.finish();
        })
        .catch((e) => {
          this.$Progress.fail();
        });
    },
    loadMore() {},
  },
};
</script>
<style>
.report_list .v-list-item__avatar {
  width: 150px !important;
}

@media (max-width: 600px) {
  /* sm breakpoint in Vuetify */
  .mb-sm-15 {
    margin-bottom: 15px;
  }
}
</style>
