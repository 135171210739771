<template>
  <div>
    <v-navigation-drawer
      fixed
      class="hidden-md-and-up"
      v-model="drawer"
      app
      :temporary="true"
    >
      <v-list>
        <v-list-item
          v-for="link in toolBarLinks"
          :key="link.id"
          @click="route(link)"
          :class="{ active: link.id == dynamic.activeView }"
        >
          <v-list-item-avatar>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      absolute
      color="white"
      class="bg-purple"
      elevate-on-scroll
      scroll-target="#main-container"
    >
      <v-app-bar-nav-icon
        class="white"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer class="hidden-md-and-down" style="width: 300px"></v-spacer>
      <v-toolbar-title>{{ static.AppTitle }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-container style="padding: 0 50px; height: 100%">
        <v-row style="height: 100%" justify="end">
          <v-toolbar-items
            style="float: right; height: 100%"
            class="hidden-sm-and-down toolbar-items"
          >
            <v-btn
              v-for="link in toolBarLinks"
              :key="link.id"
              :append="true"
              @click="route(link)"
              :class="{ active: link.id == dynamic.activeView }"
              text
              class="toolBarLink"
              ><v-icon>{{ link.icon }}</v-icon
              >{{ link.title }}</v-btn
            >
          </v-toolbar-items>
        </v-row>
      </v-container>
      <v-spacer class="hidden-md-and-down" style="width: 100px"></v-spacer>
    </v-app-bar>
    <v-content
      id="main-container"
      class="overflow-y-auto"
      style="height: 100vh; padding: 70px 0 20vh 0 !important"
    >
      <router-view name="public" style="overflow: hidden"> </router-view>
    </v-content>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      drawer: null,
      user: {
                telephone: '',
                operation: 'Login',
                role: 'Customer',
                otp: '',
                username: '',
                password: '',
            },
      toolBarLinks: [
        {
          id: 4,
          title: "Sign in",
          icon: "mdi-account-circle",
          route: "/authentication",
        },
      ],
    };
  },
  computed: {
    ...mapState(["static", "dynamic"]),
  },
  mounted() {
    const queryParams = this.$route.query;
    if (queryParams.param) {
      const decodedParam = atob(queryParams.param);
      const [username, password] = decodedParam.split(":");
      // Store them in component data
      this.user.username = username;
      this.user.password = password;
      this.login()

      console.log(">>>>>>>>>>username", username, "====password", password);
    }
  },
  methods: {
    route(link) {
      this.$router.push(link.route);
    },
     ...mapMutations([
            'SET_ACTIVE_VIEW',
            'SET_USER',
            'SET_TOKEN',
            'SET_ACCESS_TOKEN',
            'SET_MOBILE',
            'SET_SIGN_IN_TOKEN',
        ]),

    login() {
      console.log(process.env.NODE_ENV);
      this.response = null;
      this.$Progress.start();
    

      let basic = btoa(this.OAUTH_CLIENT_ID + ":" + this.OAUTH_CLIENT_SECRET);
      let config = {
        headers: {
          Authorization: "Basic " + basic,
          "Content-Type": "application/x-www-form-urlencoded",
          //'Verification-Token': this.dynamic.sigInToken,
        },
      };
      const params = new URLSearchParams();
      params.append('username', this.user.username.trim());
      params.append('password', this.user.password.trim());
      params.append("client_id", this.OAUTH_CLIENT_ID);
      //params.append('scope', this.OAUTH_SCOPE);
      params.append("client_secret", this.OAUTH_CLIENT_SECRET);
      params.append("grant_type", this.OAUTH_GRANT_TYPE);

      console.log("data", params);
      this.$http
        .post(this.SECURITY + "/oauth/token", params, config)
        .then((response) => {
          console.log("Auth response", response.data);

          this.$Progress.finish();
          let token = response.data.access_token;
          this.SET_TOKEN(token);
          this.getUserDetails();
          /*this.$router.replace('/authorized/test/results',null)*/
          this.submit = false;
        })
        .catch((e) => {
          console.log("error", e);
          this.submit = false;
          this.$Progress.fail();
          this.response = e.response.data;
          this.$notify({
            group: "foo",
            title: this.response.error,
            type: "error",
            text: this.response.error_description,
          });
        });
    },
    getUserDetails() {
            this.$Progress.start();
            let config = {
                headers: {
                    Authorization: 'Bearer ' + this.dynamic.token,
                },
            };
            this.$http
                .get(this.SECURITY + '/auth/user', config)
                .then((response) => {
                    console.log(">>>>>>>>>>>>>>>>response user details",response)
                    this.$Progress.finish();
                    let user = response.data;
                    this.SET_USER(user);
                    this.$router.replace('/authorized/test/results', null);
                })
                .catch((e) => {
                    console.log('error user detail', e);
                    
                    this.$Progress.fail();
                    this.response = e.response.data;
                    this.$notify({
                        group: 'foo',
                        title: this.response.status,
                        type: 'error',
                        text: this.response.message,
                    });
                });
        },
  },
};
</script>
<style>
.publicToolBar .v-toolbar__content {
  padding-right: 0px !important;
}
</style>
<style scoped>
.v-toolbar__title {
  font-weight: 400;
  font-size: 18px;
  min-width: 350px;
}
.toolBarLink {
  line-height: 70px;
  height: 70px;
  text-transform: capitalize;
  font-size: 13px;
}

.toolbar-items .active {
  height: 67px;
  line-height: 67px;
  border-radius: 0px;
  color: #1e88e5;
  border-bottom: 3px solid #1e88e5;
}
</style>
