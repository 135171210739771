<template>
<v-container>

    <v-row justify="center">
        <v-col md="6" lg="4" sm="12" xs="12" style="margin-top: 10vh;">
            <v-card class="elevation-6">
                <v-card class="primary" dark style="padding: 15px;border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;text-align: center;position: relative">
                    <v-avatar size="50" color="primary">
                        <v-icon style="font-size: 60px" dark>mdi-account-circle</v-icon>
                    </v-avatar>
                    <h2 class="font-weight-light text-md-center text-lg-center form-title" style="margin-top: 20px">
                        Sign In
                    </h2>
                    <h2 class="font-weight-regular text-md-center text-lg-center body-1">
                        Signin to your {{ static.AppTitle }} account
                    </h2>
                </v-card>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validateNewForm">
                        <v-text-field v-model="user.username" :rules="rules.username" hint="Input your username ." prepend-icon="email" label="Username" type="text"></v-text-field>

                        <v-text-field required @keyup.enter.native="validateNewForm" :append-icon="show ? 'visibility' : 'visibility_off'" @click:append="show = !show" :type="show ? 'text' : 'password'" :rules="rules.passwordRules" v-model="user.password" prepend-icon="security" name="password" label="Password" id="password">
                        </v-text-field>

                        <v-btn style="margin-top: 40px" block type="button" :loading="submit" :disabled="submit" @click="validateNewForm" color="primary">
                            <v-icon>mdi-arrow-right</v-icon> Continue
                            <template v-slot:loader>
                                <span class="custom-loader">
                                    <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import {
    mapState,
    mapMutations
} from 'vuex';
export default {
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: process.env.VUE_APP_APPTITLE,
        // all titles will be injected into this template
        titleTemplate: '%s | Customer login page',
    },
    data() {
        return {
            user: {
                telephone: '',
                operation: 'Login',
                role: 'Customer',
                otp: '',
                username: '',
                password: '',
            },
            response: null,
            submit: false,
            valid: true,
            show: false,
            rules: {
                username: [(v) => !!v || 'Username. is required'],
                passwordRules: [(v) => !!v || 'password is required'],
            },
        };
    },
    computed: {
        ...mapState(['static', 'dynamic']),
    },
       mounted() {
    const queryParams = this.$route.query;
    if (queryParams.param) {
      const decodedParam = atob(queryParams.param);
      const [username, password] = decodedParam.split(":");
      // Store them in component data
      this.user.username = username;
      this.user.password = password;
      this.login()

     
    }
  },
    methods: {
        ...mapMutations([
            'SET_ACTIVE_VIEW',
            'SET_USER',
            'SET_TOKEN',
            'SET_ACCESS_TOKEN',
            'SET_MOBILE',
            'SET_SIGN_IN_TOKEN',
        ]),

       
        login() {
            console.log(process.env.NODE_ENV)
            this.response = null;
            this.$Progress.start();
            this.submit = true;

            let basic = btoa(this.OAUTH_CLIENT_ID + ':' + this.OAUTH_CLIENT_SECRET);
            let config = {
                headers: {
                    Authorization: 'Basic ' + basic,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    //'Verification-Token': this.dynamic.sigInToken,
                },
            };
            const params = new URLSearchParams();
            params.append('username', this.user.username.trim());
            params.append('password', this.user.password.trim());
            params.append('client_id', this.OAUTH_CLIENT_ID);
            //params.append('scope', this.OAUTH_SCOPE);
            params.append('client_secret', this.OAUTH_CLIENT_SECRET);
            params.append('grant_type', this.OAUTH_GRANT_TYPE);

            console.log('data', params);
            this.$http
                .post(this.SECURITY + '/oauth/token', params, config)
                .then((response) => {
                    console.log('Auth response', response.data);

                    this.$Progress.finish();
                    let token = response.data.access_token;
                    this.SET_TOKEN(token);
                    this.getUserDetails();
                    /*this.$router.replace('/authorized/test/results',null)*/
                    this.submit = false;
                })
                .catch((e) => {
                    console.log('error', e);
                    this.submit = false;
                    this.$Progress.fail();
                    this.response = e.response.data;
                    this.$notify({
                        group: 'foo',
                        title: this.response.error,
                        type: 'error',
                        text: this.response.error_description,
                    });
                });
        },
        getUserDetails() {
            this.$Progress.start();
            let config = {
                headers: {
                    Authorization: 'Bearer ' + this.dynamic.token,
                },
            };
            this.$http
                .get(this.SECURITY + '/auth/user', config)
                .then((response) => {
                    this.$Progress.finish();
                    let user = response.data;
                    this.SET_USER(user);
                    this.$router.replace('/authorized/test/results', null);
                })
                .catch((e) => {
                    console.log('error', e);
                    this.submit = false;
                    this.$Progress.fail();
                    this.response = e.response.data;
                    this.$notify({
                        group: 'foo',
                        title: this.response.status,
                        type: 'error',
                        text: this.response.message,
                    });
                });
        },
        validateNewForm() {
            if (this.$refs.form.validate()) {
                this.login();
            }
        },
    },
};
</script>

<style>
.v-text-field .v-input__prepend-inner {
    padding-top: 5px;
}

.or {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #d1d1d1;
    line-height: 0.1em;
    margin: 30px 0 20px;
}

.or span {
    background: #fff;
    padding: 0 10px;
}
</style>
